import { Theme } from "@griddo/core";

const theme: Theme.BaseTheme = {
	selector: '[data-theme="secondary-theme"]',
	id: "secondary-theme",
	name: "Secondary theme",
	primitives: [],
};

export default theme;
