import accent from "./accent";
import base from "./base";
import defaultTheme from "./default";
import defaultAlt from "./default-alt";
import inverse from "./inverse";

const bundle = {
	...base,
	subthemes: [accent, defaultTheme, defaultAlt, inverse],
};

export default bundle;
