import { Theme } from "@griddo/core";
import primitives from "@themes/global-theme/colors";

const subtheme: Theme.BaseTheme = {
	selector: '[data-subtheme="accent"]',
	id: "accent",
	name: "The accent theme",
	primitives: [
		...primitives,

		// -------------------------------------------------------------------------
		// MISC
		// -------------------------------------------------------------------------

		{
			id: "brandColors",
			name: "brandColors",
			values: [{ cssVar: "--brand-1", value: "#DB005B" }],
		},

		{
			id: "support",
			name: "support",
			values: [
				{ cssVar: "--success", value: "#FFEA96" },
				{ cssVar: "--focus-1", value: "#1510F7" },
				{ cssVar: "--focus-2", value: "#1510F7" },
			],
		},

		// -------------------------------------------------------------------------
		// TEXT AND ICONS
		// -------------------------------------------------------------------------

		{
			id: "text",
			name: "text",
			values: [
				{ cssVar: "--text-1", value: "#FFFFFF" },
				{ cssVar: "--text-2", value: "#EAC9D7" },
				{ cssVar: "--text-3", value: "#EAC9D7" },
				{ cssVar: "--text-4", value: "#FFEA96" },
				{ cssVar: "--text-5", value: "#FFFFFF" },
				{ cssVar: "--text-6", value: "#FFFFFF" },
				{ cssVar: "--text-7", value: "#FFFFFF" },
				{ cssVar: "--text-8", value: "#FFFFFF" },
				{ cssVar: "--text-inverse", value: "#010D20" },
				{ cssVar: "--text-disabled", value: "#DCDCDC" },
			],
		},

		{
			id: "icon",
			name: "icon",
			values: [
				{ cssVar: "--icon-1", value: "#FFFFFF" },
				{ cssVar: "--icon-inverse", value: "#010E21" },
				{ cssVar: "--icon-disabled", value: "#B0B0B0" },
			],
		},

		// -------------------------------------------------------------------------
		// BUTTONS
		// -------------------------------------------------------------------------

		{
			id: "backgroundButton",
			name: "backgroundButton",
			values: [
				// 1
				{ cssVar: "--bg-button-1", value: "#FFFFFF" },
				{ cssVar: "--bg-button-1-hover", value: "#FFDBEA" },
				{ cssVar: "--bg-button-1-active", value: "#FFFFFF99" },
				{ cssVar: "--bg-button-1-focus", value: "#FFFFFF" },
				{ cssVar: "--bg-button-1-disabled", value: "#B0B0B0" },
				// 3
				{ cssVar: "--bg-button-3-hover", value: "#FFFFFF33" },
				{ cssVar: "--bg-button-3-active", value: "#FFDBEA" },
				// 4
				{ cssVar: "--bg-link-button-4-hover", value: "#FFDBEA" },
			],
		},

		{
			id: "borderButton",
			name: "borderButton",
			values: [
				// 1
				{ cssVar: "--border-button-1-focus", value: "#1510F7" },
				// 3
				{ cssVar: "--border-button-3", value: "#FFFFFF" },
				{ cssVar: "--border-button-3-hover", value: "#FFFFFF" },
				{ cssVar: "--border-button-3-active", value: "#FFFFFF" },
				{ cssVar: "--border-button-3-focus", value: "#1510F7" },
				{ cssVar: "--border-button-3-disabled", value: "#B0B0B0" },
				// 4
				{ cssVar: "--border-button-4-focus", value: "#1510F7" },
			],
		},

		{
			id: "textButton",
			name: "textButton",
			values: [
				// 1
				{ cssVar: "--text-button-1", value: "#010D20" },
				{ cssVar: "--text-button-1-hover", value: "#010D20" },
				{ cssVar: "--text-button-1-active", value: "#010D20" },
				{ cssVar: "--text-button-1-focus", value: "#010D20" },
				{ cssVar: "--text-button-1-disabled", value: "#DCDCDC" },
				// 3
				{ cssVar: "--text-button-3", value: "#FFFFFF" },
				{ cssVar: "--text-button-3-hover", value: "#FFFFFF" },
				{ cssVar: "--text-button-3-active", value: "#010D20" },
				{ cssVar: "--text-button-3-focus", value: "#FFFFFF" },
				{ cssVar: "--text-button-3-disabled", value: "#B0B0B0" },
				// 4
				{ cssVar: "--text-link-button-4", value: "#FFFFFF" },
				{ cssVar: "--text-link-button-4-hover", value: "#FFDBEA" },
				{
					cssVar: "--text-link-button-4-active",
					value: "#FFFFFF99",
				},
				{ cssVar: "--text-link-button-4-focus", value: "#FFFFFF" },
				{
					cssVar: "--text-link-button-4-disabled",
					value: "#B0B0B0",
				},
			],
		},

		// -------------------------------------------------------------------------
		// LINKS
		// -------------------------------------------------------------------------

		{
			id: "textLink",
			name: "textLink",
			values: [
				// 1
				{ cssVar: "--text-link-1", value: "#FFFFFF" },
				{ cssVar: "--text-link-1-hover", value: "#EAC9D7" },
				{ cssVar: "--text-link-1-active", value: "#FFFFFF" },
				{ cssVar: "--text-link-1-focus", value: "#FFFFFF" },
				{ cssVar: "--text-link-1-disabled", value: "#FFFFFF4D" },
				// Menu
				{ cssVar: "--text-link-menu", value: "#FFFFFF" },
				{ cssVar: "--text-link-menu-hover", value: "#FFFFFF" },
				{ cssVar: "--text-link-menu-active", value: "#FFFFFF" },
				{ cssVar: "--text-link-menu-focus", value: "#FFFFFF" },
				{
					cssVar: "--text-link-menu-disabled",
					value: "#FFFFFF4D",
				},
			],
		},

		// -------------------------------------------------------------------------
		// BORDERS
		// -------------------------------------------------------------------------

		{
			id: "border",
			name: "border",
			values: [
				{ cssVar: "--border-1", value: "#FFFFFF" },
				{ cssVar: "--border-2", value: "#EAC9D7" },
			],
		},

		// -------------------------------------------------------------------------
		// BACKGROUNDS
		// -------------------------------------------------------------------------

		{
			id: "background",
			name: "background",
			values: [
				{ cssVar: "--bg-ui", value: "#DB005B" },
				{ cssVar: "--bg-1", value: "#DB005B" },
				{ cssVar: "--bg-2", value: "#B50052" },
				{ cssVar: "--bg-3", value: "#A8004C" },
				{ cssVar: "--bg-4", value: "#FFFFFF" },
				{ cssVar: "--bg-disabled", value: "#FFFFFF99" },
			],
		},

		// -------------------------------------------------------------------------
		// OVERLAYS
		// -------------------------------------------------------------------------

		{
			id: "overlay",
			name: "overlay",
			values: [
				{ cssVar: "--overlay-3", value: "#08022C80" },
				{ cssVar: "--overlay-4", value: "#08022C66" },
			],
		},
	],
};

export default subtheme;
