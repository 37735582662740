import { Theme } from "@griddo/core";
import primitives from "@themes/global-theme/colors";

const subtheme: Theme.BaseTheme = {
	selector: '[data-subtheme="default"]',
	id: "default",
	name: "The default theme",
	primitives: [
		...primitives,

		// -------------------------------------------------------------------------
		// MISC
		// -------------------------------------------------------------------------

		{
			id: "brandColors",
			name: "brandColors",
			values: [{ cssVar: "--brand-1", value: "#DB005B" }],
		},

		// -------------------------------------------------------------------------
		// TEXT AND ICONS
		// -------------------------------------------------------------------------

		{
			id: "text",
			name: "text",
			values: [
				{ cssVar: "--text-5", value: "#010E21" },
				{ cssVar: "--text-6", value: "#010E21" },
				{ cssVar: "--text-7", value: "#010E21" },
				{ cssVar: "--text-8", value: "#010E21" },
			],
		},

		// -------------------------------------------------------------------------
		// BUTTONS
		// -------------------------------------------------------------------------

		{
			id: "backgroundButton",
			name: "backgroundButton",
			values: [
				// 1
				{ cssVar: "--bg-button-1", value: "#DB005B" },
				{ cssVar: "--bg-button-1-hover", value: "#FF006A" },
				{ cssVar: "--bg-button-1-active", value: "#DA035D" },
				{ cssVar: "--bg-button-1-focus", value: "#DB005B" },
				{ cssVar: "--bg-button-1-disabled", value: "#F5DEE8" },
				// 3
				{ cssVar: "--bg-button-3-hover", value: "#FF006A33" },
				{ cssVar: "--bg-button-3-active", value: "#FF006A" },
				{ cssVar: "--bg-button-3-disabled", value: "#FFFFFF" },
				// 4
				{ cssVar: "--bg-link-button-4-hover", value: "#FF006A" },
			],
		},

		{
			id: "borderButton",
			name: "borderButton",
			values: [
				// 1
				{ cssVar: "--border-button-1-focus", value: "#1510F7" },
				// 3
				{ cssVar: "--border-button-3", value: "#DB005B" },
				{ cssVar: "--border-button-3-hover", value: "#DB005B" },
				{ cssVar: "--border-button-3-active", value: "#DB005B" },
				{ cssVar: "--border-button-3-focus", value: "#1510F7" },
				{ cssVar: "--border-button-3-disabled", value: "transparent" },
				// 4
				{ cssVar: "--border-button-4-focus", value: "#1510F7" },
			],
		},

		{
			id: "textButton",
			name: "textButton",
			values: [
				// 1
				{ cssVar: "--text-button-1-disabled", value: "#DCB2C3" },
				// 3
				{ cssVar: "--text-button-3", value: "#DB005B" },
				{ cssVar: "--text-button-3-hover", value: "#DB005B" },
				{ cssVar: "--text-button-3-focus", value: "#FF006A" },
				{ cssVar: "--text-button-3-disabled", value: "#DCB2C3" },
				// 4
				{ cssVar: "--text-link-button-4", value: "#DB005B" },
				{ cssVar: "--text-link-button-4-hover", value: "#FF006A" },
				{ cssVar: "--text-link-button-4-active", value: "#DA035D" },
				{ cssVar: "--text-link-button-4-focus", value: "#DB005B" },
				{
					cssVar: "--text-link-button-4-disabled",
					value: "#DCB2C3",
				},
			],
		},

		// -------------------------------------------------------------------------
		// LINKS
		// -------------------------------------------------------------------------

		{
			id: "textLink",
			name: "textLink",
			values: [
				// 1
				{ cssVar: "--text-link-1", value: "#DB005B" },
				{ cssVar: "--text-link-1-hover", value: "#E34E8C" },
				{ cssVar: "--text-link-1-active", value: "#CB457D" },
				{ cssVar: "--text-link-1-focus", value: "#DB005B" },
				{ cssVar: "--text-link-1-disabled", value: "#DCB2C3" },
				// Menu
				{ cssVar: "--text-link-menu-hover", value: "#F71070" },
				{ cssVar: "--text-link-menu-active", value: "#D41C69" },
				{ cssVar: "--text-link-menu-disabled", value: "#DCB2C3" },
			],
		},

		// -------------------------------------------------------------------------
		// BORDERS
		// -------------------------------------------------------------------------

		{
			id: "border",
			name: "border",
			values: [{ cssVar: "--border-2", value: "#DB005B" }],
		},

		// -------------------------------------------------------------------------
		// BACKGROUNDS
		// -------------------------------------------------------------------------

		{
			id: "background",
			name: "background",
			values: [
				{ cssVar: "--bg-2", value: "#F7F3F4" },
				{ cssVar: "--bg-3", value: "#EFE6E8" },
			],
		},
	],
};

export default subtheme;
