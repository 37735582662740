import { Theme } from "@griddo/core";

import inverseTheme from "../main-theme/inverse";

const subtheme: Theme.BaseTheme = {
	selector: '[data-subtheme="inverse"]',
	id: "inverse",
	name: "The inverse theme",
	primitives: [
		...(inverseTheme.primitives as Array<Theme.Primitive>),

		// -------------------------------------------------------------------------
		// MISC
		// -------------------------------------------------------------------------

		{
			id: "brandColors",
			name: "brandColors",
			values: [{ cssVar: "--brand-1", value: "#DB005B" }],
		},

		// -------------------------------------------------------------------------
		// TEXT AND ICONS
		// -------------------------------------------------------------------------

		{
			id: "text",
			name: "text",
			values: [{ cssVar: "--text-4", value: "#DB005B" }],
		},

		// -------------------------------------------------------------------------
		// BUTTONS
		// -------------------------------------------------------------------------

		{
			id: "textButton",
			name: "textButton",
			values: [
				// 1
				{ cssVar: "--text-button-1", value: "#DB005B" },
				{ cssVar: "--text-button-1-hover", value: "#DB005B" },
				{ cssVar: "--text-button-1-active", value: "#DB005B" },
				{ cssVar: "--text-button-1-focus", value: "#DB005B" },
				// 3
				{ cssVar: "--text-button-3-active", value: "#DB005B" },
			],
		},
	],
};

export default subtheme;
